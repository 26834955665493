import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';

import '../generic-page.scss';
import './index.scss';
import { Box } from '../../modules/common/components/box';

const AFKGuidesCodes: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>Active Codes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/afk/categories/category_codes.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Active Promo Codes</h1>
          <h2>
            All active AFK Journey promo codes for free Diamonds and Invite
            Letters. Also learn how to redeem them in the game.
          </h2>
          <p>
            Last updated: <strong>10/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Active Codes" />
        {/* <StaticImage src="../../images/afk/sad.webp" alt="No codes" /> */}
        <div className="codes">
          <Box isCentered>
            <p className="code">AFKJCOMMUNITY</p>
            <p className="rewards">100 Diamonds</p>
            <p className="date">Released on 08.08.2024</p>
          </Box>
          <Box isCentered>
            <p className="code">PLAYAFKJOURNEY</p>
            <p className="rewards">200 Diamonds</p>
            <p className="date">Released on 08.08.2024</p>
          </Box>
          <Box isCentered>
            <p className="code">AFKJRPG888</p>
            <p className="rewards">300 Diamonds</p>
            <p className="date">Released on 08.08.2024</p>
          </Box>
          <Box isCentered>
            <p className="code">AFKJPC</p>
            <p className="rewards">100 Diamonds</p>
            <p className="date">Released on 08.08.2024</p>
          </Box>
          <Box isCentered>
            <p className="code">AFKJ8888</p>
            <p className="rewards">188 Diamonds</p>
            <p className="date">Released on 08.08.2024</p>
          </Box>
          <Box isCentered>
            <p className="code">AFKJ9999</p>
            <p className="rewards">188 Diamonds</p>
            <p className="date">Released on 08.08.2024</p>
          </Box>
          <Box isCentered>
            <p className="code">AFKJ10</p>
            <p className="rewards">10 Summon Tickets</p>
            <p className="date">Released on 07.09.2024</p>
          </Box>
          <Box isCentered>
            <p className="code">AFKJICESEASON</p>
            <p className="rewards">200 Diamonds + 3x Summon Tickets</p>
            <p className="date">Released on 02.01.2025</p>
          </Box>
          <Box isCentered>
            <p className="code">AFKJWhiteridge</p>
            <p className="rewards">200 Diamonds</p>
            <p className="date">Released on 02.01.2025</p>
          </Box>
          <Box isCentered>
            <p className="code">ChainsofEternity</p>
            <p className="rewards">200 Diamonds</p>
            <p className="date">Released on 02.01.2025</p>
          </Box>
          <Box isCentered>
            <p className="code">SNOWLORSAN</p>
            <p className="rewards">200 Diamonds</p>
            <p className="date">Released on 02.01.2025</p>
          </Box>
          <Box isCentered>
            <p className="code">
              AFKJfuyuyo <span className="new">NEW!</span>
            </p>
            <p className="rewards">200 Diamonds + Gold</p>
            <p className="date">Released on 10.02.2025</p>
          </Box>
          <Box isCentered>
            <p className="code">
              MVH8L2O34J <span className="new">NEW!</span>
            </p>
            <p className="rewards">500 Diamonds + Gold</p>
            <p className="date">Released on 10.02.2025</p>
          </Box>
        </div>
        <SectionHeader title="How to redeem codes" />
        <StaticImage src="../../images/afk/generic/codes.webp" alt="Guides" />
        <p>
          You can redeem AFK Journey codes in the game, by going to the{' '}
          <strong>Settings</strong> screen first and from there you just have to
          press the <strong>Promo Codes</strong> button to open the window where
          you can input your codes! The image above shows where to find the{' '}
          <strong>Promo Codes</strong> redemption feature.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesCodes;

export const Head: React.FC = () => (
  <Seo
    title="Active Codes | AFK Journey | Prydwen Institute"
    description="All active AFK Journey promo codes for free Diamonds and Invite Letters. Also learn how to redeem them in the game."
    game="afk"
  />
);
